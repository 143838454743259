<template>
  <div class="container">
    <slot />
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n();
const url = useRequestURL();
const route = useRoute();

useHead({
  meta: [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0, maximum-scale=1',
    },
    {
      name: 'description',
      content: t('meta.description'),
    },
    {
      name: 'og:title',
      content: t('meta.title'),
    },
    {
      name: 'og:type',
      content: 'website',
    },
    {
      name: 'og:url',
      content: `${url.href + route.path}`,
    },
    {
      name: 'og:image',
      content: t('meta.image'),
    },
    {
      name: 'og:description',
      content: t('meta.description'),
    },
  ],
});
</script>

<style scoped lang="scss">
  .container {
    max-width: 1440px;
    margin: 12px auto;
  }
</style>
